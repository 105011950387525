<template>
  <div>
    <div class="overflow-x-hidden justify-between pt-16">
      <!-- Gesy -->
      <div class="container-inner mx-auto relative pb-16" id="gesy">
        <h2 class="font-bold text-4xl text-center pb-16">
          {{ $t("pages.gesy.gesy") }}
        </h2>
        <p class="text-justify">{{ $t("pages.gesy.gesy_text1") }}</p>
        <DoctorsList />
        <p class="text-justify">{{ $t("pages.gesy.gesy_text4") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import DoctorsList from "../components/DoctorsList";
export default {
  metaInfo() {
    var locale = this.$i18n.locale;
    return {
      title: locale == "gr" ? "ΓΕΣΥ | MedInDiet" : "GESY | MedInDiet",
      meta:
        locale == "gr"
          ? [
              {
                name: "description",
                content: "Online Διατροφή",
              },
              {
                name: "keywords",
                content:
                  "διαιτολόγος γεσυ, διαιτολόγος γεσυ Κύπρος, διαιτολόγος γεσυ Λευκωσία, διατροφολόγος γεσυ, διατροφολόγος γεσυ Κύπρος, διατροφολόγος γεσυ Λευκωσία",
              },
            ]
          : [
              {
                name: "description",
                content: "Online Diet",
              },
              {
                name: "keywords",
                content:
                  "dietitian gesy, dietitian gesy Cyprus, dietitian gesy Nicosia",
              },
            ],
      link: [
        { rel: "canonical", href: "https://medindiet.com/gr/gesy" },
        {
          rel: "alternate",
          hreflang: "el",
          href: "https://medindiet.com/gr/gesy",
        },
        {
          rel: "alternate",
          hreflang: "en",
          href: "https://medindiet.com/en/gesy",
        },
      ],
    };
  },
  components: {
    DoctorsList,
  },
};
</script>
