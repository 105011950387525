<template>
  <div class="container-inner mx-auto py-8">
    <section class="body-font overflow-hidden">
      <p class="text-center font-semibold pb-8">
        {{ $t("pages.gesy.doctors_header") }}
      </p>
      <div class="flex flex-wrap -m-4">
        <div class="p-4 md:w-1/2 w-full" id="one_off_package">
          <div
            class="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden"
          >
            <p
              class="flex items-center mb-2"
              v-for="doctor in doctors1"
              :key="doctor"
            >
              <span
                class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-500 text-white rounded-full flex-shrink-0"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2.5"
                  class="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5" />
                </svg>
              </span>
              {{ doctor }}
            </p>
          </div>
        </div>
        <div class="p-4 md:w-1/2 w-full" id="full_package">
          <div
            class="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden"
          >
            <p
              class="flex items-center mb-2"
              v-for="doctor in doctors2"
              :key="doctor"
            >
              <span
                class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-500 text-white rounded-full flex-shrink-0"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2.5"
                  class="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5" />
                </svg>
              </span>
              {{ doctor }}
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import json_en from "@/locales/en.json";
import json_gr from "@/locales/gr.json";

export default {
  data() {
    return {
      doctors1_gr: json_gr.pages.gesy.doctors1,
      doctors1_en: json_en.pages.gesy.doctors1,
      doctors2_gr: json_gr.pages.gesy.doctors2,
      doctors2_en: json_en.pages.gesy.doctors2,
    };
  },
  computed: {
    doctors1() {
      return this.$i18n.locale === "gr" ? this.doctors1_gr : this.doctors1_en;
    },
    doctors2() {
      return this.$i18n.locale === "gr" ? this.doctors2_gr : this.doctors2_en;
    },
  },
};
</script>
